/** @jsx jsx */

/**
 * This has to match the URL in the Talkable Admin which is currently set to
 * /pages/referral-dashboard but we may want to change this later.
 */

import { jsx } from 'theme-ui'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ReferralDashboard from '~/components/Account/ReferralDashboard'

const ReferralDashboardPage = ({ location: { pathname }, data }) => {
  return (
    <Layout showBreadcrumbs={false}>
      <Metadata title="SUPER73 Referral Dashboard" />
      <ReferralDashboard />
    </Layout>
  )
}

export default ReferralDashboardPage
