/** @jsx jsx */

import { useEffect } from 'react'
import { Flex, Container, jsx } from 'theme-ui'
import { useUser, UserStatus } from '@chordcommerce/gatsby-theme-performance'
import PageSpinner from '~/components/Generic/PageSpinner'
import LoginForm from '~/components/Account/Login/Form'
import { LoginFormWrapper, Title } from './styles'

const ReferralDashboard = () => {
  const { user, status } = useUser()

  useEffect(() => {
    if (
      typeof window._talkableq !== 'undefined' &&
      status === UserStatus.Succeeded
    ) {
      window._talkableq.push([
        'authenticate_customer',
        {
          email: user.email,
          first_name: user.firstName,
          last_name: user.lastName,
          traffic_source: ''
        }
      ])
      window._talkableq.push(['register_affiliate', {}])
    }
  }, [status, user])

  return (
    <Container
      variant="fullWidth"
      sx={{
        width: ['100%', '80%'],
        paddingTop: 'calc(var(--nav-bar-height) + 4rem)',
        paddingBottom: 'calc(var(--nav-bar-height) + 4rem)'
      }}
    >
      <Flex sx={{ justifyContent: 'center' }}>
        <Title
          as="h2"
          sx={{
            fontSize: [6, 8],
            pb: [3, 4],
            mb: [4, 6]
          }}
        >
          SUPER73 Referral Dashboard
        </Title>
      </Flex>
      {status === UserStatus.Succeeded ? (
        <div id="talkable-offer"></div>
      ) : status === UserStatus.Idle ? (
        <LoginFormWrapper>
          <LoginForm pathOnSubmit="/pages/referral-dashboard" />
        </LoginFormWrapper>
      ) : (
        <PageSpinner />
      )}
    </Container>
  )
}

export default ReferralDashboard
